import React from 'react';
import ScrollToTop from './utils/scrollToTop';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HomeAirbag from 'pages/homes/HomeAirbag';

const App = () => {
  return (
    <>
      <Helmet />
      <Router>
        <ScrollToTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL + '/'}`}
              component={HomeAirbag}
            />
            <Route exact component={HomeAirbag} />
          </Switch>
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
