import { Helmet } from 'react-helmet';
import React, { Fragment } from 'react';
import './HomeAirbag.scss';

const HomeAirbag = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Reto en ruta</title>
      </Helmet>
      <div className="content">Reto en ruta</div>
    </Fragment>
  );
};

export default HomeAirbag;
